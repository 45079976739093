<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input class="w220 mr10" placeholder="请输入运费模板名称" />
        <el-button type="primary" plain>搜索</el-button>
      </div>
      <el-button style="margin-left: auto" type="primary"
        >+新建运费模板</el-button
      >
    </div>
    <commonTable :tableData="tableData">
      <template v-slot:table>
        <el-table-column prop="templateName" align="center" label="模板名称" />
        <el-table-column prop="updateTime" align="center" label="更新时间" />
        <el-table-column
          prop="relatedGoods"
          align="center"
          label="关联商品"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="操作">
          <template>
            <el-button type="text">详情</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
// @ts-ignore
import commonTable from "@/components/common/commonTable";
export default {
  name: "freightManagement",
  components: {
    commonTable,
  },
  data() {
    return {
      tableData: [
        {
          templateName: "包邮",
          updateTime: "2019-09-24  12:06:33",
          relatedGoods: "10",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>